import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from '@hotwired/stimulus-webpack-helpers';

window.Stimulus = Application.start();
const stimulusControllers = import.meta.webpackContext('../controllers', { recursive: true, regExp: /_controller\.js$/ });
const stimulusComponents = import.meta.webpackContext('../../components', { recursive: true, regExp: /_controller\.js$/ });

window.Stimulus.load(
  definitionsFromContext(stimulusControllers).concat(
    definitionsFromContext(stimulusComponents),
  ),
);
