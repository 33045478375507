import { Controller } from 'stimulus';
import { useDebounce } from 'stimulus-use';

import coopenerEmailRegex from '../shared/coopener_email_regex.js';
import buildUrl from '../shared/url.js';

export default class extends Controller {
  static targets = [
    'email',
    'alert',
    'alreadyClient',
    'modalVisibility',
    'markVisibility',
    'connectedVisibility',
    'emailReplace',
    'submitButton',
    'alreadyClientInput',
  ];

  static values = {
    alreadytext: String,
    loadingtext: String,
  }

  static debounces = ['checkForEmail'];

  controller = null;

  initialize() {
    this.checkForEmail();
  }

  checkForEmail() {
    useDebounce(this, 350);
    this.unsetAlreadyClient();

    if (this.emailTarget.value.match(coopenerEmailRegex)) {
      this.verifyEmail({ email: this.emailTarget.value });
    }
  }

  async verifyEmail({ email }) {
    this.setLoading();

    this.abortPreviousFetch();
    this.controller = new AbortController();
    try {
      this.submitButtonTarget.disabled = true;
      const response = await fetch(
        buildUrl('/api/v1/login/verify', { email }),
        { signal: this.controller.signal },
      );
      this.submitButtonTarget.disabled = false;
      if (response.ok) {
        this.setAlreadyClient();
      } else {
        this.unsetLoading();
      }
    } catch (error) {
      if (error.name === 'AbortError') return;
      throw error;
    }
  }

  abortPreviousFetch() {
    if (this.controller) {
      this.controller.abort();
    }
  }

  setLoading() {
    this.alertTarget.innerHTML = this.loadingtextValue;
  }

  unsetLoading() {
    this.alertTarget.innerHTML = null;
  }

  setAlreadyClient() {
    this.alreadyClientTarget.value = true;
    this.alertTarget.innerHTML = this.alreadytextValue;
    if (this.hasEmailReplaceTarget) {
      this.emailReplaceTarget.value = this.emailTarget.value;
    }
    this.modalVisibilityTarget.classList.remove('hidden');
    this.alreadyClientInputTarget.value = true;
  }

  unsetAlreadyClient() {
    this.alreadyClientInputTarget.value = false;
    this.alreadyClientTarget.value = false;
    this.alertTarget.innerText = null;
    this.connectedVisibilityTarget.classList.add('hidden');
    this.markVisibilityTarget.classList.add('hidden');
    this.modalVisibilityTarget.classList.add('hidden');
  }
}
