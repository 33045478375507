import { Controller } from '@hotwired/stimulus';
import buildUrl from '../shared/url.js';

export default class extends Controller {
  static targets = ['codeInput', 'error', 'success'];

  connect() {
    this.checkCode();
  }

  checkCode() {
    this.errorTarget.hidden = true;
    this.successTarget.hidden = true;

    const discountCode = this.codeInputTarget.value;
    if (discountCode !== '') {
      this.#verifyDiscountCode(discountCode);
    }
  }

  async #verifyDiscountCode(discountCode) {
    await fetch(buildUrl('/api/v1/discount_codes', { code: discountCode }))
      .then(async (response) => {
        if (response.ok) {
          const reply = await response.json();
          if (reply.data.attributes.validity === true) {
            this.#onSuccess(reply.data.attributes.description);
          }
        } else {
          this.#onError();
        }
      });
  }

  #onSuccess(description) {
    this.successTarget.hidden = false;
    this.errorTarget.hidden = true;
    this.successTarget.querySelector('.message__description').textContent = description; // Ensure you have a MessageComponent inside onSuccess target
  }

  #onError() {
    this.successTarget.hidden = true;
    this.errorTarget.hidden = false;
  }
}
